

















































































































import TriggersListSectionTitle from '@/components/TriggersListSectionTitle.vue'
import checkChat from '@/assets/utils/checkChat'
import VuTriggers from '@/includes/logic/VuTriggers'
import { InputSetups } from '@/mixins/input-setups'
import TriggerCardListWrapper from '@/components/TriggerCardListWrapper.vue'
import CreateTriggerModal from '@/components/CreateTriggerModal/CreateTriggerModal.vue'
import { TriggersMode } from '@/includes/types/triggers/Enums'

import { UseFields } from 'piramis-base-components/src/components/Pi'
import Icon from 'piramis-base-components/src/components/Icon/Icon.vue'
import { EntityTypes } from 'piramis-base-components/src/components/SelectEntityWizard/includes/types'

import { Component, Mixins } from 'vue-property-decorator'
import arrayMove from 'array-move'

@Component({
  'components': {
    TriggersListSectionTitle,
    CreateTriggerModal,
    TriggerCardListWrapper,
    Icon,
  },
})
export default class TriggersList extends Mixins(UseFields, InputSetups) {
  triggersLoading = false

  searchQuery = ''

  triggerName = ''

  get triggerMode(): boolean {
    return this.$store.state.chatState.chat.config.triggers_mode === TriggersMode.Parallel
  }

  set triggerMode(value: boolean) {
    this.$store.commit('pi/EXEC', {
      'fn': () => {
        this.$store.state.chatState.chat.config.triggers_mode = value ? TriggersMode.Parallel : TriggersMode.Linear
      }
    })
  }

  get activeTriggerIds(): Array<string> {
    return this.$store.state.chatState.chat.triggers
  }

  get inactiveTriggers(): Array<any> {
    return this.$store.state.triggersState.frontendTriggers
      .filter((item: any) => !this.activeTriggerIds.includes(item.id))
      .sort((a: any, b: any) => {
        const includesA = this.activeTriggerIds.includes(a.id)
        const includesB = this.activeTriggerIds.includes(b.id)

        if (includesA !== includesB) {
          return +includesB - +includesA
        }

        return a.name.localeCompare(b.name)
      })
  }

  get queriedTriggers(): Array<any> {
    return this.$store.state.triggersState.frontendTriggers
      .filter((t: any) => t.name.toLowerCase().includes(this.searchQuery.trim().toLowerCase()))
      .sort((a: any) => {
        if (this.activeTriggerIds.includes(a.id)) {
          return -1
        }

        return 1
      })
  }

  get activeTriggers(): Array<any> {
    return this.$store.state.triggersState.frontendTriggers
      .filter((item: any) => this.activeTriggerIds.includes(item.id))
      .sort((a: any, b: any) => this.activeTriggerIds.indexOf(a.id) - this.activeTriggerIds.indexOf(b.id))
  }

  get hasTriggerLimits(): string {
    let message = ''

    const chat = this.$store.state.chatState.chat
    const limits = chat.limits
    const max_triggers = limits.max_triggers
    const triggersLength = chat.triggers.length

    if (triggersLength > max_triggers) {
      message += `<span>${ this.$t('max_triggers') }: <b>${ triggersLength }</b> ${ this.$t('of') } <b>${ max_triggers }</b></span>`
    }

    return message
  }

  moveToDirection(data: { id: string, direction: 'left' | 'right' }): void {
    const { id, direction } = data
    const index = this.activeTriggerIds.indexOf(id)

    this.$store.commit('setChatTriggers', arrayMove(this.activeTriggerIds, index, direction === 'left' ? index - 1 : index + 1))
  }

  moveToIndex(data: { id: string, newIndex: number }): void {
    const { id, newIndex } = data
    const index = this.activeTriggerIds.indexOf(id)

    this.$store.commit('setChatTriggers', arrayMove(this.activeTriggerIds, index, newIndex))
  }

  hasLimits(trigger: any): any {
    let message = ''

    const limits = this.$store.state.chatState.chat.limits
    const max_trigger_actions = limits.max_trigger_actions
    const max_trigger_conditions = limits.max_trigger_conditions

    const count = (data: any) => {
      let i = 0

      if (data && data.length) {
        data.forEach(function(val: any) {
          if (val.actions) {
            val.actions.forEach(() => i++)
          } else {
            val.forEach(() => i++)
          }

        })
      }

      return i
    }

    const actionsCount = count(trigger.actions)
    if (actionsCount > max_trigger_actions) {
      message += `<p>${ this.$t('max_trigger_actions') }: <b>${ actionsCount }</b> ${ this.$t('of') } <b>${ max_trigger_actions }</b></p>`
    }

    const conditionsCount = count(trigger.conditions)
    if (conditionsCount > max_trigger_conditions) {
      message += `<p>${ this.$t('max_trigger_conditions') }: <b>${ conditionsCount }</b> ${ this.$t('of') } <b>${ max_trigger_conditions }</b></p>`
    }

    return message
  }

  removeTrigger(id: string): void {
    VuTriggers.deleteFrontendTrigger(id).then(() => {
      this.$store.commit('disableTrigger', id)
    })
  }

  gotoTrigger(actionType: 'new' | 'edit' | 'watch', triggerId?: string): void {
    const query: any = {}

    if (triggerId) {
      query['triggerId'] = triggerId
    }

    if (checkChat(this.$route.path)) {
      this.$router.push({
        name: 'chat_trigger',
        params: {
          [EntityTypes.CHAT_ID]: this.$store.state.chatState.chat.chat_id,
          actionType
        },
        query: query,
      })
    } else {
      this.$router.push({
        name: 'global_trigger',
        params: {
          actionType,
        },
        query: query,
      })
    }
  }

  editTrigger(id: string): void {
    this.gotoTrigger('edit', id)
  }

  watchTrigger(id: string): void {
    this.gotoTrigger('watch', id)
  }

  copyTrigger(id: string): void {
    VuTriggers.copyTrigger(id)
    this.gotoTrigger('new')
  }

  addToChat(id: string): void {
    this.$store.commit('enableTrigger', id)
  }

  removeFromChat(id: string): void {
    this.$store.commit('disableTrigger', id)
  }

  updateTriggers(): void {
    this.triggersLoading = true

    this.$store.dispatch('updateTriggers')
      .finally(() => {
        this.triggersLoading = false
      })
  }

  mounted(): void {
    this.updateTriggers()
  }
}
