






























import CreateTriggerModal from './CreateTriggerModal/CreateTriggerModal.vue'

import { Component, Emit, Prop } from 'vue-property-decorator'
import Vue from 'vue'

@Component({
  components: {
    CreateTriggerModal
  }
})
export default class TriggersListSectionTitle extends Vue {
  @Prop() title!:string

  @Prop({ type: Boolean }) showAddButton!:boolean

  @Emit('addButtonClick')
  addNewButtonClick(): void {}

  //
  triggerModalOpen = false

  triggerName = ''

}
