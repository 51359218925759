












































































































































































































import { InputSetups } from '@/mixins/input-setups'
import { RawTriggerData } from '@/components/TriggerSetup/logic/types/types'

import List from 'piramis-base-components/src/components/Pi/components/List/List.vue'
import { ListDirection } from 'piramis-base-components/src/components/Pi/components/List/logic/types'
import Icon from 'piramis-base-components/src/components/Icon/Icon.vue'
import { UseFields } from 'piramis-base-components/src/components/Pi'
import WindowResizeMixin from 'piramis-base-components/src/logic/helpers/WindowResizeMixin'

import { Component, Emit, Mixins, Prop } from 'vue-property-decorator'

@Component({
  components: {
    List,
    Icon
  },
  data() {
    return {
      ListDirection
    }
  }
})
export default class TriggerCardListWrapper extends Mixins<UseFields, InputSetups, WindowResizeMixin>(UseFields, InputSetups, WindowResizeMixin) {
  @Prop() triggers!: Array<RawTriggerData>

  @Prop({ default: () => [] }) userTriggers!: Array<string>

  @Prop({ type: Function }) hasLimits!: (item: any) => string

  @Emit('removeTrigger')
  removeTrigger(id: string): string {
    return id
  }

  @Emit('addToActive')
  addToActive(id: string): string {
    return id
  }

  @Emit('removeFromActive')
  removeFromActive(id: string): string {
    return id
  }

  @Emit('editTrigger')
  editTrigger(id: string): string {
    return id
  }

  @Emit('copyTrigger')
  copyTrigger(id: string): string {
    return id
  }

  @Emit('watchTrigger')
  watchTrigger(id: string): string {
    return id
  }

  @Emit('moveTriggerDirection')
  moveTriggerDirection(id: string, direction: 'left' | 'right'): { id: string, direction: 'left' | 'right' } {
    return { id, direction }
  }

  @Emit('moveTriggerIndex')
  moveTriggerIndex(id: string, newIndex:number): { id: string, newIndex:number } {
    return { id, newIndex }
  }

  changeIndexModalOpen = false

  newIndex = 1

  selectedTrigger: RawTriggerData | null = null

  get isMobileWidth() {
    return this.windowWidth <=767
  }

  onOk():void {
    if (this.selectedTrigger && this.selectedTrigger.id) {
      this.moveTriggerIndex(this.selectedTrigger.id, this.newIndex - 1)

      this.changeIndexModalOpen = false
      this.selectedTrigger = null
    }
  }

  handleEditActionClick(trigger:any):void {
    this.editTrigger(trigger.id)
  }

  isActiveTrigger(id: string): boolean {
    return this.userTriggers.includes(id)
  }

  getMarkerColor(id: string): string {
    let returnValue = 'transparent'
    const updatedTriggerId = this.$store.state.triggersState.updatedTriggerId

    if (this.isActiveTrigger(id)) {
      returnValue = 'rgb(40, 199, 111)'
    }

    if (updatedTriggerId !== null && updatedTriggerId === id.toString()) {
      returnValue = 'rgb(247, 205, 54)'
    }

    return returnValue
  }

  selectTrigger(trigger:RawTriggerData):void {
    this.selectedTrigger = trigger

    this.changeIndexModalOpen = true
  }

  disableMove(id: string, direction: 'left' | 'right'): boolean {
    const triggerIndex = this.triggers.findIndex(trigger => trigger.id === id)

    if (direction === 'left') {
      return triggerIndex === 0
    }

    if (direction === 'right') {
      return triggerIndex === this.triggers.length - 1
    }

    return true
  }
}
