
















import PageTitle from '@/components/PageTitle.vue'
import TriggersList from '@/components/TriggersList.vue'

import { Component } from 'vue-property-decorator'
import Vue from 'vue'

@Component({
  'components': {
    TriggersList,
    PageTitle
  },
})
export default class Triggers extends Vue {

}
